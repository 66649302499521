.omg-rule-step {
  padding: 1rem 2rem 4rem 2rem;
}

.omg-rule-step3 {
  padding-bottom: 0;
  .card {
    max-height: 30vh;
    overflow-y: scroll;
    padding: 0.5rem 1rem;
    .form-check {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &-img {
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}

.list-group-item {
  &:hover {
    cursor: grabbing;
  }
  &-content {
    .button-delete {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.modal-tool-container {
  .modal-tool-header {
    background: #008ade;
    color: #fff;
  }
  a {
    text-decoration: none;
  }

  .button-tool-group {
    margin-top: 2rem;
    flex-direction: column-reverse;
    border: 1px solid #eef1f5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    gap: 0.5em;
    padding: 1rem 3rem;
    background: #f9fafb;
    margin-top: 0;
    a {
      width: 100%;
      text-align: center;
    }
  }

  .progress-bar-tool {
    li {
      position: inherit;
      display: flex;
      flex-direction: row-reverse;
      // justify-content: space-around;
      justify-content: center;
      align-items: center;
      // &:last-child {
      &:before {
        content: none;
      }
      // }
      div {
        position: inherit;
        left: 10px;
      }
    }
    span {
      // background-color: #008ade;
      // width: 2rem;
      // height: 2rem;
    }
  }

  .tool-content {
    border: 1px solid #eef1f5;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.tool-table-no-data {
  position: absolute;
  background-color: #fff;
}
