// Here you can add other styles
// Hide calendar
// .fc-today-button,
// .fc-prev-button,
// .fc-next-button {
//   display: none !important;
// }

div.fc {
  // width: 65% !important;
  td {
    height: 50px !important;
    .fc-daygrid-day-frame {
      height: 50px !important;
    }
  }
}
