// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
@import 'timeline';
@import 'tool';

.widget {
  height: 180px;
}

.avatar-md {
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  .avatar-img {
    height: 100%;
    object-fit: cover;
  }
}

.rdrDefinedRangesWrapper {
  font-size: 0.8rem !important;
  width: 9rem !important;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.pagination {
  margin-bottom: 0;
}

.fix-button {
  padding-left: 0 !important;
  padding-top: 0;
  text-decoration: none;
}

.accordion-button {
  padding: 0.6rem;
}

.widget-avg-session {
  overflow: auto;
}

.tooltip-inner,
.tooltip-arrow {
  &:hover {
    cursor: pointer;
  }
}

.star-performer {
  position: relative;
  &-item {
    width: 25%;
    border-radius: 5px;
    padding: 1rem 1rem;
    margin: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: #417bfb;
    color: #fff;
  }
  &-gauge {
    display: flex;
    justify-content: center;
    .text-group {
      display: none !important;
    }
  }
}

.performer {
  position: absolute;
  // top: 2rem;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0 0.5rem;
  left: 12%;
  background-color: #ffce02;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  .star {
    margin-bottom: 0;
    filter: invert(1);
  }
}

.logout-button {
  cursor: pointer;
}
