/* Import */

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700|Oswald:400,300,700);

/* Variables */

$background: #321fdb;

$color-primary: #ddd;
$color-light: white;
$spacing: 30px;
$radius: 2px;

$date: 120px;
$dotborder: 2px;
$dot: 10px;
$line: 2px;

$font-title: 'Oswald', sans-serif;
$font-text: 'Source Sans Pro', sans-serif;

/* Base */

// body {
//   background: $background;
//   font-size: 16px;
// }

strong {
  font-weight: 600;
}

// h1 {
//   font-family: $font-title;
//   letter-spacing: 1.5px;
//   color: $color-light;
//   font-weight: 100;
//   font-size: 2.4em;
// }

#content {
  text-align: center;
}

/* Timeline */

.timeline {
  transform: translateX(50%);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-left: $line solid $color-primary;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  background: fade($color-light, 3%);
  color: fade(white, 80%);
  font-family: $font-text;
  margin: $spacing auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: $spacing;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 30%;

  //   h1,
  //   h2,
  //   h3 {
  //     font-family: $font-title;
  //     letter-spacing: 1.5px;
  //     font-weight: 100;
  //     font-size: 1.4em;
  //   }

  .event {
    border-bottom: 1px dashed fade($color-light, 10%);
    // padding-bottom: ($spacing * 0.15);
    margin-bottom: $spacing;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: ((($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5) * -1;
      // left: -36.5px;
      color: fade($color-light, 40%);
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 0.9em;
      min-width: $date;
    }

    &:after {
      box-shadow: 0 0 0 $dotborder fade($color-primary, 100%);
      // left: ($spacing + $line + ($dot * 0.35)) * -1;
      left: -36.5px;
      background: lighten($background, 5%);
      border-radius: 50%;
      height: $dot;
      width: $dot;
      content: '';
      top: 0px;
    }
  }
}
